<template>
    <div>
        2.监测年报  
    </div>
</template>
<script>
export default {
    name: "annual_report"
}
</script>
<style lang="scss" scoped>

</style>
